import React, { useState, useEffect } from "react";
import bg from "../assets/networkgif.webp"
import bg2 from "../assets/networkgif2.gif"
import gplay from "../assets/gplay.png"
import Header from "../components/Header";
import Footer from "../components/Footer";
import hobbies from "../utils/hobbies.json"
import { Button, TextField } from "@mui/material";
import {Autocomplete} from "@mui/joy"
import {db, auth} from "../App"
import { addDoc, collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { Search } from "@mui/icons-material";

import { getAuth, signInWithEmailAndPassword,setPersistence,browserSessionPersistence} from "firebase/auth";
import { useNavigate } from "react-router-dom";



function getWindowSize() {
  const {innerWidth, innerHeight} = window;
  return {innerWidth, innerHeight};
}

const Contact: React.FC = () => {

  const [windowSize, setWindowSize] = useState(getWindowSize());
  const [move_1, set_next] = useState<boolean>(false)
  const [name, set_name] = useState<string>("")
  const [email, set_email] = useState<string>("")
  const [message, set_message] = useState<string>("")
  const [password, set_password] = useState<string>("")
  const [is_popup,set_is_popup] = useState<boolean>(false) //TBA in edit mode
  const [popup_message, set_popup_message] = useState<string>("");

  const navigate = useNavigate();



  useEffect(() => {
    
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    console.log('window resied', window.innerWidth)

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });



function validateEmail(email:any) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

function hasWhiteSpace(s:any) {

  if (/\s/.test(s)) {
    return true
  }
  else{

    return false
  }

}

function password_validator(input:string){


  let regex = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$")
  
  let test = regex.test(input);

  if(test === true){

    if (hasWhiteSpace(input)=== true){

      return false;
    }
    else{
      return true;
    }

  }
  else{

    return test;
  }

}


const signIn = async()=>{

    if(validateEmail(email) === false){

      set_is_popup(true)
      set_popup_message("Please enter a valid email.")

      setTimeout(() => {

        set_is_popup(false)
        set_popup_message("Error.")

        
      }, 2000);

      return;

  }
  else if(name.length < 1){

      set_is_popup(true)
      set_popup_message("Please enter your name.")

      setTimeout(() => {

        set_is_popup(false)
        set_popup_message("Error.")

        
      }, 2000);

      return;


  }
  else if(message.length < 10){

    set_is_popup(true)
    set_popup_message("Please write something.")

    setTimeout(() => {

      set_is_popup(false)
      set_popup_message("Error.")

      
    }, 2000);

    return;


}

    const userProjectsColRef = collection(db, 'contact');
    const newProjectDocRef = doc(userProjectsColRef);
    addDoc(collection(newProjectDocRef, 'messages'), {
    /* ... data ... */
    name:name,
    email:email,
    message:message
    }).then(()=>{

        set_is_popup(true)
        set_popup_message("Thank you ! We will revert back to you soon.")

        setTimeout(() => {

            window.location.reload()
            
        }, 2000);
    
    })




}


 if(window.innerWidth > 650){

  return(
  <div style={{margin:0, padding:0, flexDirection:'column', backgroundImage:`url(${bg2})`, backgroundRepeat:'no-repeat', backgroundSize:'cover', backgroundColor:'black', overflow:'scroll', height:'200vh', display:'flex', justifyContent:'center', alignItems:'center'}} >
    
  <div style={{display:'flex', height:'100vh',width:'100vw', flexDirection:'column', backgroundColor:"#00000088",justifyContent:'center', alignItems:'center'}}>
  
  <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
  <h1 style={{fontSize:56, fontFamily:'Montserrat', fontWeight:700, position:'absolute', top:40, textAlign:'center'}}>Area</h1>
  <h5 style={{color:'white', fontFamily:"Montserrat", fontSize:18, position:'absolute', top:130,}}>Contact Us</h5>
  </div>  
  
  
  <div style={{marginTop:-80,
  transition: "all .2s", justifyContent:'center',alignItems:'center', 
  minHeight:400, flexDirection:'column',display:'flex',
  position:'absolute',top:'32%'}}>
  <p style={{textAlign:'left', float:'left', alignSelf:'flex-start', marginTop:10}}>Your Name</p>
  <TextField placeholder="Your Name" onChange={(event)=>set_name(event.target.value)} style={{width:400, maxWidth:400, backgroundColor:'white', borderRadius:2}} />
  <p style={{textAlign:'left', float:'left', alignSelf:'flex-start', marginTop:10}}>Your Email</p>
  <TextField placeholder="Your Email" onChange={(event)=>set_email(event.target.value)} style={{width:400, maxWidth:400, backgroundColor:'white', borderRadius:2}} />
  <p style={{textAlign:'left', float:'left', alignSelf:'flex-start', marginTop:10}}>Your Message</p>
  <TextField placeholder="Your Message"     sx={{
      "& fieldset": { border: 'none' },
    }}  multiline={true} onChange={(event)=>set_message(event.target.value)} style={{width:400, maxWidth:400, height:200, backgroundColor:'white', borderRadius:2}} />
  
  <div style={{marginTop:60}}>
  <Button variant="contained" onClick={()=>signIn()}  style={{paddingLeft:60, marginLeft:5, paddingRight:60, fontStyle:'bold', fontWeight:400, fontSize:14, backgroundColor:"#00bfff",fontFamily:"Montserrat"}}>Contact Us</Button>
  </div>
  </div>

  
  </div>
  {is_popup === true ?
        
        <div id="popupBox" style={{textAlign:'center', color:'black'}}>

          <p><b>{popup_message}</b></p>


        </div>
        :
        null
        }
     </div>

   


 
  )
 }
 else{

  return (


    <div style={{margin:0, padding:0, flexDirection:'column', backgroundImage:`url(${bg2})`, backgroundRepeat:'no-repeat',overflow:'scroll', backgroundSize:'cover', backgroundColor:'black', height:'200vh', maxWidth:window.innerWidth, width:window.innerWidth, minWidth:window.innerWidth, display:'flex', justifyContent:'center', alignItems:'center'}} >

    <div style={{display:'flex', height:'100vh',width:window.innerWidth, flexDirection:'column', backgroundColor:"#00000088",justifyContent:'center', alignItems:'center'}}>
    
    <div style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
    <h1 style={{fontSize:32, fontFamily:'Satisfy', fontWeight:700, position:'absolute', top:40, textAlign:'center'}}>Area</h1>
    <h5 style={{color:'white', fontFamily:"Montserrat", fontSize:18, position:'absolute', top:100}}>Contact Us</h5>
    </div>  
    
    <div style={{marginTop:-80,
    transition: "all .2s", justifyContent:'center',alignItems:'center', width:'100%', maxWidth:'100%',
    minHeight:400, flexDirection:'column',display:'flex',padding:20,
    position:'absolute',top:'32%'}}>

    <p style={{textAlign:'left', float:'left', alignSelf:'flex-start', marginTop:10}}>Your Name</p>
    <TextField placeholder="Your Name" onChange={(event)=>set_name(event.target.value)} style={{width:'90%', maxWidth:400, backgroundColor:'white', borderRadius:2}} />
    <p style={{textAlign:'left', float:'left', alignSelf:'flex-start', marginTop:10}}>Your Email</p>
    <TextField placeholder="Your Email" onChange={(event)=>set_email(event.target.value)} style={{width:'90%', maxWidth:400, backgroundColor:'white', borderRadius:2}} />
    <p style={{textAlign:'left', float:'left', alignSelf:'flex-start', marginTop:10}}>Your Message</p>
    <TextField placeholder="Your Message"     sx={{
      "& fieldset": { border: 'none' },
    }}  multiline={true} onChange={(event)=>set_message(event.target.value)} style={{width:'90%', maxWidth:400, height:200, backgroundColor:'white', borderRadius:2}} />
    
    <div style={{marginTop:60}}>
    <Button variant="contained" onClick={()=>signIn()}  style={{paddingLeft:60, marginLeft:5, paddingRight:60, fontStyle:'bold', fontWeight:700, fontSize:14, backgroundColor:"#00bfff"}}>Submit</Button>
    </div>
    </div>

    
    </div>
    {is_popup === true ?
        
        <div id="popupBox" style={{textAlign:'center', color:'black'}}>

          <p><b>{popup_message}</b></p>


        </div>
        :
        null
        }
     </div>
    

);
 }

  
};

export default Contact;
